import React from 'react';
import HeroText from '../HeroText/HeroText';
import ImageText from '../ImageText/ImageText';
import OptionTileGrid from '../OptionTile/OptionTileGrid';
import { options } from './TileOptions';
import ReachMe from '../ReachMe/ReachMe';
import { contacts } from './ContactOptions';
import ProsAndCons from '../ProsAndCons/ProsAndCons';
import { cons, pros } from './ProsAndConsOptions';

const Professional: React.FC = () => {
    return (
        <div>
            <HeroText 
                headline="Dream Professionally"
                subheadline="Imagine what we could achieve together..." 
            />
            <ImageText 
                imageSrc={`${process.env.PUBLIC_URL}/images/expertisealt.svg`}
                imageAlt="Sample Image"
                headline="What is it all about?"
                text={`
***Life is simple***
\n\b\n
Yes, not always. \n
Yes, not for everyone.\n
However, it could for sure be worse...\n
And **it could always be better**.\n
Having experience in various areas, I aim to apply my knowledge, as well as to share it. This is a way of making life better for everyone.\n
\n\b\n
***With this website I hope to achieve these 3 goals:***
`}
            />
            <OptionTileGrid options={options} />
            <ProsAndCons pros={pros} cons={cons} />
            <ReachMe
                headline="Get In Touch"
                text="Opportunities made simple. Let's discuss how we can make this world a bit more professional:"
                contacts={contacts}
            />
        </div>
        
    );
};

export default Professional;