import React from 'react';
import usePersonalStyles from './usePersonalStyles';
import BwHeadline from './BlackAndWhite/BwHeadline';
import ImageRotator from './BlackAndWhite/ImageRotator/ImageRotator';

const Personal: React.FC = () => {
    usePersonalStyles();
    return <div>
      <BwHeadline headline = "Life isn't black and white. This page was. Well, it's not anymore." />
      <ImageRotator imageNames={['me0.jpg', 'me1.jpg', 'me2.jpg', 'me3.jpg', 'me4.jpg', 'me5.jpg', 'me6.jpg', 'me7.jpg', 'me8.jpg', 'me9.jpg', 'me10.jpg' ]} />
  </div>;
};

export default Personal;