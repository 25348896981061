// src/components/Projects/Projects.tsx
import React from 'react';
import ExcitingLink from '../ExcitingLink/ExcitingLink';
import ImageText from '../ImageText/ImageText';
import useDynamicStyles from './useDynamicStyles';

const Projects: React.FC = () => {
  useDynamicStyles();

  return (
    <div>
      <ExcitingLink 
        headline="MVPs, Projects, Tools" 
        subheadline="There is no free time. You owe others. When job's done - you owe yourself." 
        link="https://deadlinetrack.com"
        cta='DeadlineTrack.com'
      />
      <ImageText
        imageSrc={`${process.env.PUBLIC_URL}/images/deadlinetrack.png`}
        imageAlt="DeadlineTrack.com landing page"
        headline="DeadlineTrack.com"
        theme='dark-red'
        text={`
**Colleagues disturbing you with questions? You love to help but deadlines are on fire? Make it public and let your manager figure out what's more important.**\n
This project is designed to help you manage your time effectively and ensure that every context switch you make is noticed and appreciated.\n
Stay organized and boost your productivity!`}
      />
      <ExcitingLink 
        headline="AI... Hype word of the decade?" 
        subheadline="A project based on AI with simple idea and big potential." 
        link="https://projects.sivach.me"
        cta='To ContAI (MVP)'
      />
      <ImageText 
        imageSrc={`${process.env.PUBLIC_URL}/images/sivachdocu2.png`}
        imageAlt="AI Documentation Visualizer"
        headline="ContAI - About"
        theme='dark-red'
        text={`
**Get the job done faster:**\n
Powered by OpenAI the project is aiming to extract and visualize the data around the tasks and projects you get to work on.\n
Improved onboarding experience / Documentation is maintained / Conceptualized by AI for Humans \n
MVP created.`}
      />
    </div>
  );
};

export default Projects;